.content {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}

.App nav {
  text-align: center;
}

.login-logo-container {
  max-width: 1000px;
}

.nav-logo-container {
  max-width: 600px;
}

.App-logo {
  height: auto;
  pointer-events: none;
  max-width: 100%;
}

.nav-logo {
  height: auto;
  pointer-events: none;
  max-width: 100%;
}

.App-header {
  background-color: #00182d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 10px;
}

.LandingPage-link {
  color: #61dafb;
}

.App-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
}

li > .App-link {
  display: block;
}

.page-title {
  text-align: center;
  margin-top: 0;
}

nav,
.user,
.user > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user {
  box-shadow: 0px 1px 5px black;
  background-color: aliceblue;
}

.user > div {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 800px;
}

nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #00182d;
  box-shadow: 0px 1px 5px black;
  padding: 10px;
}

nav a {
  color: #61dafb;
}

.column {
  padding: 5px 10px;
}

.fill {
  flex: 1;
}
